.character_bg {
    width: 75px;
    height: 75px;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: cover;
}

.avatar {
    width: 100%;
    height: 100%;
    border-radius: .04rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
}

.character_container {
    text-align: center;
}
