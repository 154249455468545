
.navbar {
    background-color: #FDFDFD;
    border-bottom: 1px solid #e0e0e0;
    --bs-navbar-padding-x: 0;
    --bs-navbar-padding-y: 0px;
    --bs-nav-link-font-size: 14px;
    --bs-nav-link-color: #333333;
    --bs-navbar-nav-link-padding-x: 12px;
}

.nav-item {
    margin-left: 16px;
    margin-right: 16px;
}

@media (min-width: 992px) {
    .navbar {
        --bs-navbar-padding-x: 16px;
        --bs-navbar-padding-y: 0;
    }

    .navbar-brand {
        margin-right: 100px !important;
    }
}

/* Dropdown container */
.dropdown {
    float: left;
    overflow: hidden;
    margin-left: 16px;
    margin-right: 16px;
}

/* Dropdown content (hidden by default) */
.dropdown-content {
    display: none;
    position: fixed;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}