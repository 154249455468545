/* Unselected */

.btn.tql-filter-btn {
    /* Global */
    --bs-btn-border-width: 2px;
    --bs-btn-border-radius: 10px;
    padding: 15px 15px 15px 20px;

    /* Normal */
    --bs-btn-border-color: #E0E0E0;

    /* Hover */
    --bs-btn-hover-color: var(--tql-primary);
    --bs-btn-hover-border-color: var(--bs-btn-border-color);
    
    /* Active */
    --bs-btn-active-border-color: var(--bs-btn-border-color);
    --bs-btn-active-color: var(--tql-primary);
    --bs-btn-active-bg: var(--tql-primary-lighter);
    
    /* Focus */
    --bs-btn-focus-shadow-rgb: var(--tql-primary-lighter-rgb);

    /* Disabled */
    --bs-btn-disabled-color: gray;
    --bs-btn-disabled-border-color: gray;
}

.tql-filter-btn .tql-checkmark {
    margin-left: 10px;
    color: var(--bs-btn-border-color);
}

/* Selected */

.tql-filter-btn.selected {
    --bs-btn-border-color: var(--tql-primary);
}
