/* Color schema */

:root {
    /* Primary color */
    --tql-primary: #C58042;
    --tql-primary-lighter-rgb: 210, 178, 149;
    --tql-primary-lighter: rgb(var(--tql-primary-lighter-rgb));
    --tql-primary-light: rgb(210, 150, 97);
    --tql-primary-dark: #a87548;
    
    /* Text color */
    --tql-text-primary: #333333;
    --tql-text-light: #585757;
}

:root, body * {
    /* Semidesign */
    --semi-color-primary: var(--tql-primary);
    --semi-color-primary-hover: var(--tql-primary-light);
    --semi-color-primary-active: var(--tql-primary-dark);
    --semi-color-primary-disabled: var(--tql-primary-lighter);

    --semi-color-link: var(--tql-primary);
    --semi-color-link-visited: var(--tql-primary);
    --semi-color-link-hover: var(--tql-primary-light);
    --semi-color-link-active: var(--tql-primary-dark);
    --semi-color-link-disabled: var(--tql-primary-lighter);
    
    /* Bootstrap */
    --bs-link-color: var(--tql-primary);
    --bs-link-hover-color: var(--tql-primary-light);
    --bs-link-active-color: var(--tql-primary-dark);
}

/* Heading */

h1, h2, h3, h4, h5, h6 {
    color: var(--tql-primary);

    margin-top: 20px;
    margin-bottom: 10px;
}

/* Button */

.btn-primary {
    /* Global */
    --bs-btn-bg: var(--tql-primary);
    --bs-btn-color: #fff;
    --bs-btn-border-color: var(--bs-btn-bg);

    /* Hover */
    --bs-btn-hover-bg: var(--tql-primary-light);
    --bs-btn-hover-border-color: var(--bs-btn-bg);
    
    /* Active */
    --bs-btn-active-bg: var(--tql-primary-dark);
    --bs-btn-active-border-color: var(--tql-primary-dark);

    /* Focus */
    --bs-btn-focus-shadow-rgb: var(--tql-primary-lighter-rgb);

    /* Disable */
    --bs-btn-disabled-bg: var(--tql-primary-lighter);
    --bs-btn-disabled-border-color: var(--tql-primary-light);
}

/* Toast */

.semi-toast-wrapper {
    margin-top: 100px; /* Avoid overlapping with nav bar */
}

/* Tabs */

.semi-tabs-pane {
    overflow: visible !important;
}

/* Bootstrap extensions */

@media(min-width: 768px) {
    .tql-align-middle-md {
        vertical-align: middle !important;
    }
}

@media (min-width: 1200px) {
    .tql-row-cols-xl-10 .col {
        flex: 0 0 auto;
        width: 10%;
    }
}

.semi-modal-small {
    max-width: 448px !important;
    width: auto !important;
}
