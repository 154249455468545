/* Banner */

.contestBanner {
    width: 100vw;
    height: 180px;
    object-fit: cover;
    object-position: center 30%;
}


@media (max-width: 767px) {
    .contestBanner  {
        height: 90px;
      }
}

@media (max-width: 991px) {
    .contestBanner  {
        height: 120px;
      }
}

