.my_btn {
    color: #C58042;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    border: 2px solid;
    border-color: #C58042;
    box-shadow: none;
}

/* .btn.active:hover{
    border: 2px solid #C58042;
    background-color: #fff;
    color: #C58042;
  } */


.my_btn:hover {
    border-color: #C58042;
    background-color: #fff;
    color: #C58042;
}

.my_btn.selected {
    border-color: #C58042;
    color: #fff;
    background-color: #C58042;
}