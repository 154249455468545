.name {
    height: 30px;
    padding: 0 0 0 15px;
    background-image: linear-gradient(-90deg, rgba(47, 47, 47, 0.00) 0%, #eae6e0 40%);
    /* border-top: 1px solid #000;
    border-right: 1px solid #000; */
    width: 90%;
    line-height: 30px;
    font-size: 12px;
}

.display_card {
    display: inline-block;
    height: 30;
    border-radius: 5px 5px 5px 5px;
    margin-right: 10px;
    margin-bottom: 3px;
    width: 100%;
    background-size: 40%;
    background-repeat: no-repeat;
    background-position: right center;
}

/* .card_content {
    height: 70px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
} */