/* AbyssBackground */

.abyss_background {
    position: absolute;
    width: 100vw;
    height: 100vh;
    max-width:100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    /* background-color: plum; */
    /* background-image: url("https://blog.zh-hant.playstation.com/tachyon/sites/8/2022/02/9442026e8da0cb236954714deabc8d9761b6cfe7-scaled.jpg"); */
    /* background: linear-gradient(transparent, white), url("https://blog.zh-hant.playstation.com/tachyon/sites/8/2022/02/9442026e8da0cb236954714deabc8d9761b6cfe7-scaled.jpg"); */
}

/* Container */

.content_container {
    position: relative;
    width: 1200px;
    margin: auto;
    background-color: #fff;
    border-radius: 5px;
    /* padding: 30px; */
}

.content_container img {
    border-radius: 5px 5px 0 0;
}

.board_container {
    padding: 30px;
    padding-top: 0;
}


/* Banner */

.abyss_banner {
    width: 100%;
    height: 250px;
    object-fit: cover;
    object-position: center 30%;
}
